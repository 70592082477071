<template>
  <div class="container1" @touchmove="handleMove" @click="handleMove">
    <div class="guide">

      <div class="step1">
        <div class="num">1</div>
        <div class="step1-text">完善基本信息</div>
      </div>
      <div class="line"></div>
      <div class="step0">
        2
      </div>
    </div>
    <div class="group">
      <div class="row">
        <div class="t">性别</div>
        <div class="expand"></div>
        <div :class="isBoy   ?   'gender boy boy-sel'   :   'gender boy'" @click="chooseGender(true)">

        </div>
        <div style="width:0.16rem"></div>
        <div :class="isBoy   ?   'gender girl'   :   'gender girl girl-sel'" @click="chooseGender(false)"></div>
      </div>
      <div class="line"></div>
      <div class="row">
        <div class="t">地区</div>
        <div class="expand"></div>
        <div class="city">中国 北京</div>
        <span class="icon-right" style="font-size:0.2rem;color: #74747A;"></span>
      </div>
      <div class="line"></div>
      <div class="row">
        <div class="t">简介</div>
      </div>
      <textarea id="desc" class="desc" v-model.trim="intro" placeholder="请填写您的简介（选填）" @click.stop=""></textarea>
    </div>
    <div style="height:2rem;flex-shrink: 0;"></div>
    <div class="footer">
      <a class="link_btn0 btn" @click="commit">下一步</a>
      <div style="height:0.4rem"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import MonitorKeyboard from '../config/monitor_keyboard.js'
export default {
  name: '',
  props: {
    msg: String
  },
  data() {
    return {
      isBoy: true,
      intro: ''
    }
  },
  watch: {
    intro(e) {
      this.saveCache()
    }
  },
  beforeDestroy() {
    this.monitorKeyboard.onEnd()
  },
  mounted() {
    this.loadCache()
    var textarea = document.querySelector('#desc');

    textarea.addEventListener('input', (e) => {
      textarea.style.height = '44px';
      textarea.style.height = e.target.scrollHeight + 'px';
    });
    this.getKeyboardState();
  },
  methods: {
    handleMove(){
      $('#desc').blur();
    },
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard();
      this.monitorKeyboard.onStart();

      // 监听虚拟键盘弹出事件
      this.monitorKeyboard.onShow(() => {
        $('.footer').hide();
      })

      //监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        $('.footer').show();
      })
    },
    chooseGender(male) {
      this.isBoy = male
      this.saveCache()
    },
    loadCache() {
      var obj = localStorage.getItem('profile_extra')
      if (obj) {
        obj = JSON.parse(obj)
        this.isBoy = obj.isBoy == '1'
        this.intro = obj.intro
      }

    },
    saveCache() {
      var obj = {
        isBoy: this.isBoy ? '1' : '0',
        intro: this.intro
      }
      localStorage.setItem('profile_extra', JSON.stringify(obj))
    },
    clearCache() {
      localStorage.removeItem('profile_extra')
    },
    commit() {
      var _this = this;
      this.$post(this.$api.userInfo, {
        'sex': this.isBoy ? 'MALE' : 'FEMALE',
        'signature': this.intro,
        'area': '北京',
        'city': '北京',
        'province': '北京',
        'country': '中国'
      }).then(data => {
        global.loginStep = 1.2
        _this.updateLoginStep()
        _this.clearCache()
      })
    },
    updateLoginStep() {
      this.$post(this.$api.frontData, {
        code: 'profile_completed_step',
        value: 1.2
      }).then(data => {
        this.$router.push('/platform').catch(err=>{})
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.container1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  color: #fff;
}

.guide {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.18rem;

  .step0 {
    margin-left: -1px;
    width: 0.44rem;
    height: 0.44rem;
    border-radius: 0.22rem;
    border: solid 0.04rem #74747AFF;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #74747AFF;
    font-size: 0.28rem;
    font-weight: bold;
    font-family: Link;
  }

  .line {
    margin-left: -1px;
    width: 0.6rem;
    height: 0.08rem;
    background: linear-gradient(270deg, #74747A 0%, #FFF700 100%);
  }

  .step1 {

    height: 0.44rem;
    background: #000000;
    box-shadow: 0rem 0.14rem 0.2rem 0rem rgba(255, 247, 0, 0.4), 0rem 0.08rem 0rem -0.02rem #FFF700;
    border-radius: 0.22rem;
    border: 0.04rem solid #FFF700;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.04rem;
    padding-right: 0.16rem;

    .num {
      width: 0.28rem;
      height: 0.28rem;
      background: #FFF700;
      border-radius: 0.14rem;
      color: #131314FF;
      font-size: 0.2rem;
      font-weight: bold;
      line-height: 0.28rem;
      font-family: Link;
    }

    .step1-text {
      color: #FFF700;
      margin-left: 0.06rem;
      font-size: 0.14rem;
      font-weight: bold;
    }
  }
}

.group {
  margin-top: 0.22rem;
  width: 3.43rem;
  box-sizing: border-box;
  background: #2C2C2E;
  border-radius: 0.24rem;
  padding: 0.2rem;

  .t {
    color: #FFF700;
    font-size: 0.16rem;
    font-weight: bold;
  }

  .line {
    height: 1px;
    background-color: #444447FF;
    margin: 0.18rem 0;
  }

  .gender {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.2rem;

    background-size: 0.24rem 0.24rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #444447FF;
  }

  .boy {
    background-image: url('../assets/images/boy.png');
  }

  .boy-sel {
    background-color: #00FFFFFF;
  }

  .girl {
    background-image: url('../assets/images/girl.png');
  }

  .girl-sel {
    background-color: #FF00AAFF;
  }

  .city {
    color: #fff;
    font-size: 0.14rem;
  }

  .desc {
    background-color: transparent;
    width: 100%;
    height: 44px;
    font-size: 0.14rem;
    line-height: 0.2rem;
    margin-top: 0.2rem;
    padding: 0;
    caret-color: #FF9900FF;
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, rgba(19, 19, 20, 0) 0%, #131314 100%);
}
</style>